import { membersOptions } from "../../../constants";
import "./styles.scss";

const Step2 = ({ onNext, membersSize, data, setData }) => {
  const handleChange = (e) => {
    setData({ ...data, membersSize: e?.value });
    onNext();
  };
  return (
    <div className="step2-wrapper">
      <div className="step2-title">How many team members do you require?</div>
      <div className="step2-sub-title">
        We have <span className="number-enginner">25+</span> engineers matching
        your criteria!
      </div>
      <div className="step2-body">
        {membersOptions?.map((e) => {
          return (
            <div
              key={e?.value}
              className={
                membersSize === e?.value
                  ? "members-item active"
                  : "members-item"
              }
              onClick={() => handleChange(e)}
            >
              <div className="members-item-img">
                <img src={e?.icon} alt="icon" />
              </div>
              <div className="members-item-label">{e?.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Step2;
