import { Layout, Menu, Collapse, Avatar } from "antd";
import logoW from "../../logo-white.svg";
const { Header, Content, Footer } = Layout;

const PageFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top">
          <div className="footer-logo">
            <p className="logo">
              <img
                src={logoW}
                className="App-logo"
                alt="teamflex"
                width={96}
                height={32}
              />
            </p>
            <p>
              Empower your teams as an employer. With our straightforward
              subscription, create a flexible, inclusive, comprehensive,
              engaging, and cost-effective support program for your business
            </p>
          </div>
          <div className="footer-nav">
            <ul>
              <li>
                <a href="https://teamflex.ai/blog">Blog</a>
              </li>
              <li>
                <a href="https://teamflex.ai/">Pricing</a>
              </li>
              <li>
                <a href="https://teamflex.ai/">Community</a>
              </li>
              <li>
                <a href="./">Contact</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://teamflex.ai/roadmap">Roadmap</a>
              </li>
              <li>
                <a href="https://teamflex.ai/changelog">Changelog</a>
              </li>
              <li>
                <a href="https://teamflex.ai/">Privacy Policy</a>
              </li>
              <li>
                <a href="https://teamflex.ai/contact">Help</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2023 Teamflex</p>
          <a className="mail" href="mailto:contact@reliasoftware.com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="rgb(231, 232, 238)"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>{" "}
            contact@reliasoftware.com
          </a>
        </div>
      </div>
    </footer>
  );
};
export default PageFooter;
