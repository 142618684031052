import { Layout, Menu, Collapse, Avatar } from "antd";
import MainForm from "../../MainForm";
import ava1 from "../../assets/images/ava1.jpeg";
import ava2 from "../../assets/images/ava2.jpeg";
import ava3 from "../../assets/images/ava3.jpeg";
import ava4 from "../../assets/images/ava4.jpeg";
import "./contact.scss";
import { Helmet } from "react-helmet";
const items = [
  {
    key: "1",
    label: "Own office? How it works?",
    children: (
      <p>
        We will have your offshore team work from an office which will be
        located in HCMC, Vietnam. This way we are responsible for deliverables
        and day to day ops. We manage all the overheads of Payroll, HR and
        office ops so that you can focus on what you do the best - BUILDING COOL
        STUFF!
      </p>
    ),
  },
  {
    key: "2",
    label: "Will engineers be working remote?",
    children: (
      <p>
        NO! We believe devs are more productive and offers best when they are
        working from an office surrounded by their colleagues. So we bring all
        your hires in one place and make sure they fit into your company culture
        even though they are miles apart.
      </p>
    ),
  },
  {
    key: "3",
    label: "How do you ensure quality?",
    children: (
      <p>
        We have a rigorous vetting process with the combination of AI and Expert
        network. Every applicant goes through a series of GPT driven coding and
        softskill tests post which they will be scheduled to get interviewed by
        our exclusive expert network which is spread across the globe. We accept
        only TOP 5% applicants to ensure we have the best of what Vietnam has to
        offer.
      </p>
    ),
  },
  {
    key: "4",
    label: "Having own office would be super expensive and unwanted overheads?",
    children: (
      <p>
        Not at all! On an average we are 50% cheaper than an onsite option.
        There are instances where we have been 70% cheaper than an onsite option
        for our clients. That's why they love us! Also, all day to day overheads
        are on us. You just focus on building. Best part is our retention! We
        have had instances where teams have stayed more than 6 years without
        attrition giving utmost stability to our clients.{" "}
      </p>
    ),
  },
];

export function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact | TeamFlex</title>
        <meta
          name="description"
          content="TeamFlex is a talent hiring platform which helps Global companies hire Vietnam's top tech talent by opening their own office. TeamFlex handles all the oerheads and day to day ops."
        />
        <meta property="og:title" content="Contact | TeamFlex" />
        <meta
          property="og:description"
          content="TeamFlex is a talent hiring platform which helps Global companies hire Vietnam's top tech talent by opening their own office. TeamFlex handles all the oerheads and day to day ops. "
        />
        {/* <meta property="og:image" content="url_to_image” /> */}
        <meta property="og:url" content="https://teamflex.ai/contact" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <main className="main">
        <div className="container">
          <div className="heading">
            <h2 className="h2">How can we help?</h2>
            <p>
              Contact our sales and support teams to schedule demos, receive
              onboarding assistance, or seek answers to any product-related
              inquiries.
            </p>
          </div>

          <div className="contact">
            <div className="contact-left">
              <div className="subhead">CONTACT FORM</div>
              <h2 className="h2">Let’s Contact</h2>
              <p>
                Connect with us to explore new possibilities and experience
                exceptional support.
              </p>
            </div>
            <MainForm />
          </div>

          <div className="question">
            <div className="question-left">
              <div className="subhead">HELP, YO!</div>
              <h2 className="h2">
                Frequently Asked
                <br /> Questions
              </h2>
              <p>
                We have compiled common questions to provide you with more
                information and enhance your overall experience.
              </p>
              <a href="./" className="btn">
                Ask Something Else
              </a>
            </div>

            <div className="question-right">
              <Collapse items={items} defaultActiveKey={["1"]} />
            </div>
          </div>

          <div className="available">
            <marquee
              behavior="scroll"
              direction="left"
              className="available-left"
            >
              • E-commerce • Blog • Life • Personal • Style • Fashion •
              Portfolio • Magazine • Education • Health • E-commerce • Blog •
              Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health • E-commerce • Blog • Life • Personal • Style •
              Fashion • Portfolio • Magazine • Education • Health • E-commerce •
              Blog • Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health
            </marquee>
            <marquee
              behavior="scroll"
              direction="right"
              className="available-right"
            >
              • E-commerce • Blog • Life • Personal • Style • Fashion •
              Portfolio • Magazine • Education • Health • E-commerce • Blog •
              Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health • E-commerce • Blog • Life • Personal • Style •
              Fashion • Portfolio • Magazine • Education • Health • E-commerce •
              Blog • Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health
            </marquee>
            <marquee
              behavior="scroll"
              direction="left"
              className="available-left"
            >
              • E-commerce • Blog • Life • Personal • Style • Fashion •
              Portfolio • Magazine • Education • Health • E-commerce • Blog •
              Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health • E-commerce • Blog • Life • Personal • Style •
              Fashion • Portfolio • Magazine • Education • Health • E-commerce •
              Blog • Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health
            </marquee>
            <marquee
              behavior="scroll"
              direction="right"
              className="available-right"
            >
              • E-commerce • Blog • Life • Personal • Style • Fashion •
              Portfolio • Magazine • Education • Health • E-commerce • Blog •
              Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health • E-commerce • Blog • Life • Personal • Style •
              Fashion • Portfolio • Magazine • Education • Health • E-commerce •
              Blog • Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health
            </marquee>
            <div className="available-text">
              <h2 className="h2">
                Tomorrow's innovation,
                <br /> available today.
              </h2>
              <div className="available-avatar">
                <Avatar.Group>
                  <Avatar src={ava1} />
                  <Avatar src={ava2} />
                  <Avatar src={ava3} />
                  <Avatar src={ava4} />
                </Avatar.Group>
                <span>Over 500+ people trusted</span>
              </div>
              <div className="group-btn">
                <a href="#" className="btn btn-light">
                  Talk to us!
                </a>
                <a href="./#" className="btn">
                  Hire Top Talent
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
