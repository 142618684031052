import step2_1 from "./assets/images/step2_1.webp";
import step2_2 from "./assets/images/step2_2.webp";
import step2_3 from "./assets/images/step2_3.webp";
import step2_4 from "./assets/images/step2_4.webp";
import step3_1 from "./assets/images/step3_1.webp";
import step3_2 from "./assets/images/step3_2.webp";
import step3_3 from "./assets/images/step3_3.webp";
import step3_4 from "./assets/images/step3_4.webp";

export const skillsOptions = [
  { label: "Python", value: "Python" },
  { label: "Swift", value: "Swift" },
  { label: "ReactNative", value: "ReactNative" },
  { label: "Android", value: "Android" },
  { label: "IOS", value: "IOS" },
  { label: "Java", value: "Java" },
  { label: "Ruby on Rails", value: "Ruby on Rails" },
  { label: "Go", value: "Go" },
  { label: "Vue.js", value: "Vue.js" },
  { label: "DevOps", value: "DevOps" },
  { label: "PHP", value: "PHP" },
  { label: "MachineLearning", value: "MachineLearning" },
  { label: "TypeScript", value: "TypeScript" },
  { label: "HTML", value: "HTML" },
  { label: "Javascript", value: "Javascript" },
  { label: "Other", value: "Other" },
  { label: "I am not sure", value: "I am not sure" },
  { label: "React", value: "React" },
  { label: "Node.js", value: "Node.js" },
  { label: "CSS", value: "CSS" },
  { label: "Angular", value: "Angular" },
];

export const membersOptions = [
  { label: "One Engineer", value: "one", icon: step2_1 },
  { label: "A small team", value: "small", icon: step2_2 },
  { label: "A large team", value: "large", icon: step2_3 },
  { label: "I am not sure", value: "not-sure", icon: step2_4 },
];

export const timeOptions = [
  { label: "Immediately", value: "Immediately", icon: step3_1 },
  { label: "In 1 to 2 weeks", value: "1-2-week", icon: step3_2 },
  { label: ">2 weeks from now", value: ">2", icon: step3_3 },
  { label: "I am not sure", value: "not-sure", icon: step3_4 },
];
