import { Link } from "react-router-dom";
import { Layout, Menu, Collapse, Avatar, Card, Space, Tag } from "antd";
import {
  StarOutlined,
  SyncOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  CloudUploadOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";
import ava1 from "../../assets/images/ava1.jpeg";
import ava2 from "../../assets/images/ava2.jpeg";
import ava3 from "../../assets/images/ava3.jpeg";
import ava4 from "../../assets/images/ava4.jpeg";
import hero from "../../assets/images/hero.png";
import analytics from "../../assets/images/analytics.webp";
import google from "../../assets/images/logo/google.webp";
import abs from "../../assets/images/logo/abs.webp";
import bakkt from "../../assets/images/logo/bakkt.webp";
import mekari from "../../assets/images/logo/mekari.webp";
import scm from "../../assets/images/logo/scm.webp";
import abbott from "../../assets/images/logo/abbott.webp";
import "./styles.scss";

const items = [
  {
    key: "1",
    label: "Own office? How it works?",
    children: (
      <p>
        We will have your offshore team work from an office which will be
        located in HCMC, Vietnam. This way we are responsible for deliverables
        and day to day ops. We manage all the overheads of Payroll, HR and
        office ops so that you can focus on what you do the best - BUILDING COOL
        STUFF!
      </p>
    ),
  },
  {
    key: "2",
    label: "Will engineers be working remote?",
    children: (
      <p>
        NO! We believe devs are more productive and offers best when they are
        working from an office surrounded by their colleagues. So we bring all
        your hires in one place and make sure they fit into your company culture
        even though they are miles apart.
      </p>
    ),
  },
  {
    key: "3",
    label: "How do you ensure quality?",
    children: (
      <p>
        We have a rigorous vetting process with the combination of AI and Expert
        network. Every applicant goes through a series of GPT driven coding and
        softskill tests post which they will be scheduled to get interviewed by
        our exclusive expert network which is spread across the globe. We accept
        only TOP 5% applicants to ensure we have the best of what Vietnam has to
        offer.
      </p>
    ),
  },
  {
    key: "4",
    label: "Having own office would be super expensive and unwanted overheads?",
    children: (
      <p>
        Not at all! On an average we are 50% cheaper than an onsite option.
        There are instances where we have been 70% cheaper than an onsite option
        for our clients. That's why they love us! Also, all day to day overheads
        are on us. You just focus on building. Best part is our retention! We
        have had instances where teams have stayed more than 6 years without
        attrition giving utmost stability to our clients.{" "}
      </p>
    ),
  },
];

export function Home() {
  return (
    <>
      <Helmet>
        <title>
          TeamFlex- Hire Vietnam's TOP 5% talent opening your own office
        </title>
        <meta
          name="description"
          content="TeamFlex is a talent hiring platform which helps Global companies hire Vietnam's top tech talent by opening their own office. TeamFlex handles all the oerheads and day to day ops."
        />
        <meta
          property="og:title"
          content="TeamFlex- Hire Vietnam's TOP 5% talent opening your own office"
        />
        <meta
          property="og:description"
          content="TeamFlex is a talent hiring platform which helps Global companies hire Vietnam's top tech talent by opening their own office. TeamFlex handles all the oerheads and day to day ops. "
        />
        {/* <meta property="og:image" content="url_to_image” /> */}
        <meta property="og:url" content="https://teamflex.ai/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
      </Helmet>
      <main className="main">
        <div className="hero">
          <div className="container">
            <div className="hero-top">
              <div className="hero-sub">OVER 300k ENGINEERS VETTED</div>
              <h1>
                Hire Vietnam's Top 5% tech
                <br /> talent
              </h1>
              <p>
                Hire the best of what Vietnam has to offer by opening your own
                office with ZERO overheads ˝
              </p>
              <a href="/contact" className="btn">
                Give Teamflex A Try
              </a>
            </div>
            <div className="available">
              <marquee
                behavior="scroll"
                direction="left"
                className="available-left"
              >
                • E-commerce • Blog • Life • Personal • Style • Fashion •
                Portfolio • Magazine • Education • Health • E-commerce • Blog •
                Life • Personal • Style • Fashion • Portfolio • Magazine •
                Education • Health • E-commerce • Blog • Life • Personal • Style
                • Fashion • Portfolio • Magazine • Education • Health •
                E-commerce • Blog • Life • Personal • Style • Fashion •
                Portfolio • Magazine • Education • Health
              </marquee>
              <marquee
                behavior="scroll"
                direction="right"
                className="available-right"
              >
                • E-commerce • Blog • Life • Personal • Style • Fashion •
                Portfolio • Magazine • Education • Health • E-commerce • Blog •
                Life • Personal • Style • Fashion • Portfolio • Magazine •
                Education • Health • E-commerce • Blog • Life • Personal • Style
                • Fashion • Portfolio • Magazine • Education • Health •
                E-commerce • Blog • Life • Personal • Style • Fashion •
                Portfolio • Magazine • Education • Health
              </marquee>
              <marquee
                behavior="scroll"
                direction="left"
                className="available-left"
              >
                • E-commerce • Blog • Life • Personal • Style • Fashion •
                Portfolio • Magazine • Education • Health • E-commerce • Blog •
                Life • Personal • Style • Fashion • Portfolio • Magazine •
                Education • Health • E-commerce • Blog • Life • Personal • Style
                • Fashion • Portfolio • Magazine • Education • Health •
                E-commerce • Blog • Life • Personal • Style • Fashion •
                Portfolio • Magazine • Education • Health
              </marquee>
              <marquee
                behavior="scroll"
                direction="right"
                className="available-right"
              >
                • E-commerce • Blog • Life • Personal • Style • Fashion •
                Portfolio • Magazine • Education • Health • E-commerce • Blog •
                Life • Personal • Style • Fashion • Portfolio • Magazine •
                Education • Health • E-commerce • Blog • Life • Personal • Style
                • Fashion • Portfolio • Magazine • Education • Health •
                E-commerce • Blog • Life • Personal • Style • Fashion •
                Portfolio • Magazine • Education • Health
              </marquee>
              <div className="available-text">
                <img src={hero} alt=""></img>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="leader">
            <div className="leader-left">
              Industry leaders
              <br /> trust us
            </div>
            <div className="leader-right">
              <div className="itm">
                <img src={google} alt="Google" width={102} height={34} />
              </div>
              <div className="itm">
                <img src={abs} alt="abs" width={92} height={34} />
              </div>
              <div className="itm">
                <img src={mekari} alt="mekari" width={125} height={34} />
              </div>
              <div className="itm">
                <img src={bakkt} alt="bakkt" width={103} height={38} />
              </div>
              <div className="itm">
                <img src={scm} alt="bakkt" width={209} height={18} />
              </div>
              <div className="itm">
                <img src={abbott} alt="abbott" width={118} height={66} />
              </div>
            </div>
          </div>
          <div className="features">
            <div className="subhead">FEATURES</div>
            <h2 className="h2">
              Explore our
              <span>amazing</span>
              features
            </h2>
            <p>
              Discover a world of incredible possibilities with our
              extraordinary features, designed to enhance your experience and
              simplify your life
            </p>
            <div className="features-list">
              <div className="box-icon">
                <div className="box-icon-icon">
                  <StarOutlined />
                </div>
                <div className="box-icon-head">Powerful Dashboard</div>
                <div className="box-icon-text">
                  Take control like never before with our powerful dashboard
                </div>
              </div>
              <div className="box-icon">
                <div className="box-icon-icon">
                  <SyncOutlined />
                </div>
                <div className="box-icon-head">Powerful Dashboard</div>
                <div className="box-icon-text">
                  Take control like never before with our powerful dashboard
                </div>
              </div>
              <div className="box-icon">
                <div className="box-icon-icon">
                  <TeamOutlined />
                </div>
                <div className="box-icon-head">Powerful Dashboard</div>
                <div className="box-icon-text">
                  Take control like never before with our powerful dashboard
                </div>
              </div>
              <div className="box-icon">
                <div className="box-icon-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(56, 67, 255)) /* {"name":"Blue/Blue 100"} */'
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                    <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                    <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                  </svg>
                </div>
                <div className="box-icon-head">Powerful Dashboard</div>
                <div className="box-icon-text">
                  Take control like never before with our powerful dashboard
                </div>
              </div>
            </div>
          </div>
          <div className="question tech" id="features-big">
            <div className="question-left">
              <div className="subhead">LATEST TECH</div>
              <h2 className="h2">
                Exploring the Latest Tech
                <br /> Trends
              </h2>
              <p>
                Stay ahead of the curve with our comprehensive coverage of the
                cutting-edge technologies shaping the future
              </p>
              <div className="sub-list">
                <div className="sub-list-box">
                  <div className="sub-list-icon">
                    <StarOutlined />
                  </div>
                  <span className="sub-list-head">Powerful Dashboard</span>
                  <p>
                    Take control like never before with our powerful dashboard
                  </p>
                </div>
                <div className="sub-list-box">
                  <div className="sub-list-icon">
                    <SyncOutlined />
                  </div>
                  <span className="sub-list-head">Always in Sync</span>
                  <p>
                    Stay seamlessly coordinated and up to date across all your
                    devices
                  </p>
                </div>
              </div>
            </div>
            <div className="question-right">
              <Marquee pauseOnHover>
                <div className="tech-marquee">
                  <div className="tech-box">
                    <div className="tech-box-icon">
                      <ThunderboltOutlined />
                    </div>
                    <div className="tech-box-head">AI Vetted talent</div>
                    <div className="tech-box-text">
                      <span>300k+ Vetted</span>
                      <span>Top 5%</span>
                      <span>GPT</span>
                      <span>Constant training</span>
                    </div>
                  </div>
                  <div className="tech-box">
                    <div className="tech-box-icon">
                      <CloudUploadOutlined />
                    </div>
                    <div className="tech-box-head">Open your own office</div>
                    <div className="tech-box-text">
                      <span>Save 50% people cost</span>
                      <span>Culture fit</span>
                      <span>Fullstack</span>
                      <span>Setup in record time</span>
                    </div>
                  </div>
                  <div className="tech-box">
                    <div className="tech-box-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="16 18 22 12 16 6"></polyline>
                        <polyline points="8 6 2 12 8 18"></polyline>
                      </svg>
                    </div>
                    <div className="tech-box-head">Onboard your talent</div>
                    <div className="tech-box-text">
                      <span>Free of cost SaaS</span>
                      <span>COR</span>
                      <span>EOR</span>
                      <span> Solution for global hiring</span>
                    </div>
                  </div>
                </div>
              </Marquee>
            </div>
          </div>
          <div className="question team">
            <div className="question-left">
              <div className="subhead">TEAM WORK</div>
              <h2 className="h2">
                Embracing the Strength of
                <br /> Teamwork
              </h2>
              <p>
                Harness the collective power of teamwork to achieve greater
                efficiency, innovation, and success
              </p>
              <div className="sub-list">
                <div className="sub-list-box">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    Organize your data
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    Work with any team
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    Business analytics
                  </p>
                </div>
                <div className="sub-list-box">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    Always in sync
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    Embedded analytics
                  </p>
                </div>
              </div>
            </div>
            <div className="question-right">
              <div className="team-inner">
                <div className="team-list">
                  <div className="team-list-head">My team</div>
                  <Marquee pauseOnHover>
                    <div className="team-list-marquee">
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava1}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava2}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava3}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava4}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava1}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava2}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava3}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava4}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava1}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava2}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava3}
                      />
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 100,
                        }}
                        src={ava4}
                      />
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
          <div className="question analytics">
            <div className="question-left">
              <div className="subhead">ANALYTICS</div>
              <h2 className="h2">The Power of Analytics</h2>
              <p>
                Empower your decision-making with data-driven insights and the
                transformative potential of analytics
              </p>
              <div className="sub-list">
                <div className="sub-list-box">
                  <span className="sub-list-count">32%</span>
                  <p>More employee retention rate</p>
                </div>
                <div className="sub-list-box">
                  <span className="sub-list-count">12 x</span>
                  <p>Faster onboarding</p>
                </div>
              </div>
            </div>
            <div className="question-right">
              <img src={analytics} alt="" />
            </div>
          </div>
          <div className="box-how" id="integrations">
            <div className="subhead">AI DRIVEN VETTING</div>
            <h2 className="h2">How it works</h2>
            <p>Combination of GPT and expert vetting brings you the best</p>
            <div class="timeline">
              <div class="timeline-tree first">
                <div class="timeline-tree-icon"></div>
                <div class="timeline-tree-count">500+</div>
                <div class="timeline-tree-text">applications/day</div>
              </div>
              <div class="timeline-tree svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 817 17"
                  id="svg2071639401"
                >
                  <path
                    d="M 11.5 9 L 809.5 9"
                    fill="transparent"
                    stroke='var(--token-239b8a64-9b42-4d09-ae56-86f123584531, rgb(110, 108, 141)) /* {"name":"Black 400"} */'
                  ></path>
                  <path
                    d="M 7.5 1 C 11.642 1 15 4.358 15 8.5 C 15 12.642 11.642 16 7.5 16 C 3.358 16 0 12.642 0 8.5 C 0 4.358 3.358 1 7.5 1 Z"
                    fill='var(--token-805041f1-1269-43ed-9421-8a5b10f0dcad, rgb(187, 208, 255)) /* {"name":"Blue 200"} */'
                  ></path>
                  <path
                    d="M 143.5 1 C 147.642 1 151 4.358 151 8.5 C 151 12.642 147.642 16 143.5 16 C 139.358 16 136 12.642 136 8.5 C 136 4.358 139.358 1 143.5 1 Z"
                    fill='var(--token-805041f1-1269-43ed-9421-8a5b10f0dcad, rgb(187, 208, 255)) /* {"name":"Blue 200"} */'
                  ></path>
                  <path
                    d="M 280.5 1 C 284.642 1 288 4.358 288 8.5 C 288 12.642 284.642 16 280.5 16 C 276.358 16 273 12.642 273 8.5 C 273 4.358 276.358 1 280.5 1 Z"
                    fill='var(--token-805041f1-1269-43ed-9421-8a5b10f0dcad, rgb(187, 208, 255)) /* {"name":"Blue 200"} */'
                  ></path>
                  <path
                    d="M 416.5 1 C 420.642 1 424 4.358 424 8.5 C 424 12.642 420.642 16 416.5 16 C 412.358 16 409 12.642 409 8.5 C 409 4.358 412.358 1 416.5 1 Z"
                    fill='var(--token-805041f1-1269-43ed-9421-8a5b10f0dcad, rgb(187, 208, 255)) /* {"name":"Blue 200"} */'
                  ></path>
                  <path
                    d="M 552.5 1 C 556.642 1 560 4.358 560 8.5 C 560 12.642 556.642 16 552.5 16 C 548.358 16 545 12.642 545 8.5 C 545 4.358 548.358 1 552.5 1 Z"
                    fill='var(--token-805041f1-1269-43ed-9421-8a5b10f0dcad, rgb(187, 208, 255)) /* {"name":"Blue 200"} */'
                  ></path>
                  <path
                    d="M 689.5 1 C 693.642 1 697 4.358 697 8.5 C 697 12.642 693.642 16 689.5 16 C 685.358 16 682 12.642 682 8.5 C 682 4.358 685.358 1 689.5 1 Z"
                    fill='var(--token-805041f1-1269-43ed-9421-8a5b10f0dcad, rgb(187, 208, 255)) /* {"name":"Blue 200"} */'
                  ></path>
                  <path
                    d="M 809.5 1 C 813.642 1 817 4.358 817 8.5 C 817 12.642 813.642 16 809.5 16 C 805.358 16 802 12.642 802 8.5 C 802 4.358 805.358 1 809.5 1 Z"
                    fill='var(--token-805041f1-1269-43ed-9421-8a5b10f0dcad, rgb(187, 208, 255)) /* {"name":"Blue 200"} */'
                  ></path>
                </svg>
                <div className="timeline-tree-2">gpt-vetting</div>
                <div className="timeline-tree-3">Screening</div>
                <div className="timeline-tree-4">
                  1-2 Technical
                  <br />
                  interview
                </div>
                <div className="timeline-tree-5">
                  soft skills
                  <br />
                  interview
                </div>
                <div className="timeline-tree-6">
                  AI-Powered
                  <br />
                  training
                </div>
              </div>
              <div class="timeline-tree last">
                <div class="timeline-tree-icon"></div>
                <div class="timeline-tree-count">Top 5%</div>
                <div class="timeline-tree-text">engineers</div>
              </div>
            </div>
          </div>
          <div className="question quality">
            <div className="question-left">
              <h2 className="h2">
                Connecting with
                <br /> Quality
              </h2>
              <p>
                Discover a world of excellence that offers you exceptional
                products and services
              </p>
            </div>

            <div className="question-right">
              <div className="quality-list">
                <div className="quality-list-itm">
                  <div className="quality-list-head">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect
                        x="9"
                        y="9"
                        width="13"
                        height="13"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    Modular
                  </div>
                  <div className="quality-list-text">
                    Create your own combination with ready-made elements and
                    styles.
                  </div>
                </div>
                <div className="quality-list-itm">
                  <div className="quality-list-head">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z"></path>
                    </svg>
                    Customisable
                  </div>
                  <div className="quality-list-text">
                    All styles and elements can be customized
                  </div>
                </div>
                <div className="quality-list-itm">
                  <div className="quality-list-head">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect x="3" y="3" width="7" height="7"></rect>
                      <rect x="14" y="3" width="7" height="7"></rect>
                      <rect x="14" y="14" width="7" height="7"></rect>
                      <rect x="3" y="14" width="7" height="7"></rect>
                    </svg>
                    4pt Grid
                  </div>
                  <div className="quality-list-text">
                    All elements and layouts use 4pt Grid system
                  </div>
                </div>
                <div className="quality-list-itm">
                  <div className="quality-list-head">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke='var(--token-cff67f23-ae37-4c37-9b34-d4c9e11264b6, rgb(149, 104, 255)) /* {"name":"Purple"} */'
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                      <polyline points="2 17 12 22 22 17"></polyline>
                      <polyline points="2 12 12 17 22 12"></polyline>
                    </svg>
                    Scalable
                  </div>
                  <div className="quality-list-text">
                    Infinite diversification at your fingertips
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="discuss">
            <div className="discuss-inner"></div>
            <h6>
              Want to discuss one time project or fixed bid bid engagement?
            </h6>
            <p>
              Check out Relia Software, a software development agency by
              TeamFlex
            </p>
          </div>
          {/* TESTIMONIALS */}
          <div className="question testi">
            <div className="question-left">
              <div className="subhead">TESTIMONIALS</div>
              <h2 className="h2">
                Hear What Others
                <br /> Say
              </h2>
              <p>
                Discover what our valued clients and partners have to say about
                their experiences working with us.
              </p>
            </div>
            <div className="question-right">
              <div className="marquee-horizontal">
                <marquee behavior="scroll" direction="up" scrollamount="3">
                  <div className="testi-box">
                    <div className="testi-box-head">CTO at Embrix</div>
                    <div className="testi-box-text">
                      TeamFlex has brought the product to launch. Everything was
                      delivered on time with great quality. The team was a
                      valued partner throughout.
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">Founder at Xeplanner</div>
                    <div className="testi-box-text">
                      Because of TeamFlex's work, the app is now ready to
                      launch. The team's workflow was super effective and they
                      had excellent communication skills. The developers on the
                      team were highly skilled and experienced and they didn't
                      need much coaching.
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">
                      Founder and CEO at Underlyn
                    </div>
                    <div className="testi-box-text">
                      The new site came out exactly as envisioned and had all
                      the requested features. TeamFlex was hardworking and
                      reliable — they went above and beyond to complete the
                      project on time and ensure the client was satisfied with
                      the final product. The team was also very professional and
                      communicative.
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">CTO at Embrix</div>
                    <div className="testi-box-text">
                      TeamFlex has brought the product to launch. Everything was
                      delivered on time with great quality. The team was a
                      valued partner throughout.
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">Founder at Xeplanner</div>
                    <div className="testi-box-text">
                      Because of TeamFlex's work, the app is now ready to
                      launch. The team's workflow was super effective and they
                      had excellent communication skills. The developers on the
                      team were highly skilled and experienced and they didn't
                      need much coaching.
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">
                      Founder and CEO at Underlyn
                    </div>
                    <div className="testi-box-text">
                      The new site came out exactly as envisioned and had all
                      the requested features. TeamFlex was hardworking and
                      reliable — they went above and beyond to complete the
                      project on time and ensure the client was satisfied with
                      the final product. The team was also very professional and
                      communicative.
                    </div>
                  </div>
                </marquee>
                <marquee behavior="scroll" direction="down" scrollamount="3">
                  <div className="testi-box">
                    <div className="testi-box-head">Sr VP at Mekari</div>
                    <div className="testi-box-text">
                      The team reliably produces exceptional results on-time.
                      TeamFlex has a personable communication style that's
                      complemented by a responsible work ethic.
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">
                      Head of Ecommerce and Ads at Google
                    </div>
                    <div className="testi-box-text">
                      Despite a high workload, the quality of TeamFlex’s work
                      remains consistent
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">Sr VP at Mekari</div>
                    <div className="testi-box-text">
                      The team reliably produces exceptional results on-time.
                      TeamFlex has a personable communication style that's
                      complemented by a responsible work ethic.
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">
                      Head of Ecommerce and Ads at Google
                    </div>
                    <div className="testi-box-text">
                      Despite a high workload, the quality of TeamFlex’s work
                      remains consistent
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">Sr VP at Mekari</div>
                    <div className="testi-box-text">
                      The team reliably produces exceptional results on-time.
                      TeamFlex has a personable communication style that's
                      complemented by a responsible work ethic.
                    </div>
                  </div>
                  <div className="testi-box">
                    <div className="testi-box-head">
                      Head of Ecommerce and Ads at Google
                    </div>
                    <div className="testi-box-text">
                      Despite a high workload, the quality of TeamFlex’s work
                      remains consistent
                    </div>
                  </div>
                </marquee>
              </div>
            </div>
          </div>
          <div className="question" id="faq">
            <div className="question-left">
              <div className="subhead">HELP, YO!</div>
              <h2 className="h2">
                Frequently Asked
                <br /> Questions
              </h2>
              <p>
                We have compiled common questions to provide you with more
                information and enhance your overall experience.
              </p>
              <a href="./" className="btn">
                Ask Something Else
              </a>
            </div>

            <div className="question-right">
              <Collapse items={items} defaultActiveKey={["1"]} />
            </div>
          </div>
          <div className="available">
            <marquee
              behavior="scroll"
              direction="left"
              className="available-left"
            >
              • E-commerce • Blog • Life • Personal • Style • Fashion •
              Portfolio • Magazine • Education • Health • E-commerce • Blog •
              Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health • E-commerce • Blog • Life • Personal • Style •
              Fashion • Portfolio • Magazine • Education • Health • E-commerce •
              Blog • Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health
            </marquee>
            <marquee
              behavior="scroll"
              direction="right"
              className="available-right"
            >
              • E-commerce • Blog • Life • Personal • Style • Fashion •
              Portfolio • Magazine • Education • Health • E-commerce • Blog •
              Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health • E-commerce • Blog • Life • Personal • Style •
              Fashion • Portfolio • Magazine • Education • Health • E-commerce •
              Blog • Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health
            </marquee>
            <marquee
              behavior="scroll"
              direction="left"
              className="available-left"
            >
              • E-commerce • Blog • Life • Personal • Style • Fashion •
              Portfolio • Magazine • Education • Health • E-commerce • Blog •
              Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health • E-commerce • Blog • Life • Personal • Style •
              Fashion • Portfolio • Magazine • Education • Health • E-commerce •
              Blog • Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health
            </marquee>
            <marquee
              behavior="scroll"
              direction="right"
              className="available-right"
            >
              • E-commerce • Blog • Life • Personal • Style • Fashion •
              Portfolio • Magazine • Education • Health • E-commerce • Blog •
              Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health • E-commerce • Blog • Life • Personal • Style •
              Fashion • Portfolio • Magazine • Education • Health • E-commerce •
              Blog • Life • Personal • Style • Fashion • Portfolio • Magazine •
              Education • Health
            </marquee>
            <div className="available-text">
              <h2 className="h2">
                Tomorrow's innovation,
                <br /> available today.
              </h2>
              <div className="available-avatar">
                <Avatar.Group>
                  <Avatar src={ava1} />
                  <Avatar src={ava2} />
                  <Avatar src={ava3} />
                  <Avatar src={ava4} />
                </Avatar.Group>
                <span>Over 500+ people trusted</span>
              </div>
              <div className="group-btn">
                <a href="#" className="btn btn-light">
                  Talk to us!
                </a>
                <a href="./#" className="btn">
                  Hire Top Talent
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
