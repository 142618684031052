import { Form, Input, Button, Select, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./styles.scss";
const { Option } = Select;

const Step4 = ({ onNext, formInput, data, setData, setHadsend }) => {
  const skillString = data?.skills.reduce(
    (acc, curr) => `${acc}${curr.label}, `,
    ""
  );
  
  const onFinish = (e) => {
   const submitData = {
    membersSize: data?.membersSize,
    time: data?.time,
    skills: skillString,
    email: e?.email,
    phone: e?.phone,
    company: e?.company,
    contact: e?.contact
  }
    onNext();
    handleSubmit(submitData);
    message.success("Submit success!");
  };
  const handleSubmit = async (submitData) => {
    if (submitData) {
      axios({
        url: `https://script.google.com/macros/s/AKfycbxSN3GioPn61q4OettQFwIuFjjzfeTygFRjlncKxLunycMt5-lUwmdmNHg2lNFsWKYpvQ/exec`,
        data: JSON.stringify(submitData),
        headers: { "content-type": "application/x-www-form-urlencoded" },
        method: "POST",
      }).then((res) => {
        if (res.data.result) {
          setHadsend(true);
        }
      });
    }
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="1">+1</Option>
        <Option value="84">+84</Option>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className="step4-wrapper">
      <div className="step4-title">
        Please sign up so we can connect you with the right developer/s from our
        network.
      </div>
      <div className="step4-body">
        <Form
          name="basic"
          size="large"
          layout="horizontal"
          initialValues={{
            remember: true,
            prefix: "1",
            ...formInput,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>

          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: "Please input your company!",
              },
            ]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
          <Form.Item
            name="contact"
            rules={[
              {
                required: true,
                message: "Please input your contact name!",
              },
            ]}
          >
            <Input placeholder="Contact Name" />
          </Form.Item>
          <Form.Item name="phone" rules={[{ required: false }]}>
            <PhoneInput
              className="custom-input"
              placeholder="Phone Number (Optional)"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Step4;
