import { lazy, Suspense, useMemo } from "react";
import { Layout, Menu, Collapse, Avatar } from "antd";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PageFooter from "./components/Footer";
import Header from "./components/Header";
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";

function App() {
  let pathName = useMemo(
    () => window.location.pathname,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname]
  );
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Layout className={!!!pathName.split("/")[1] ? "App home" : "App"}>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
          <PageFooter />
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
