import { timeOptions } from "../../../constants";
import "./styles.scss";

const step3 = ({ onNext, time, data, setData }) => {
  const handleChange = (e) => {
    setData({ ...data, time: e?.value });
    onNext();
  };
  return (
    <div className="step3-wrapper">
      <div className="step3-title">
        When do you need the developer to start?
      </div>
      <div className="step3-sub-title">
        Decide when you will start your project together!
      </div>
      <div className="step3-body">
        {timeOptions?.map((e) => {
          return (
            <div
              key={e?.value}
              className={
                time === e?.value ? "members-item active" : "members-item"
              }
              onClick={() => handleChange(e)}
            >
              <div className="members-item-img">
                <img src={e?.icon} alt="icon" />
              </div>
              <div className="members-item-label">{e?.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default step3;
