import { useState } from "react";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import "./styles.scss";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";

const MainForm = () => {
  const [step, setStep] = useState(1);
  const [hadsend, setHadsend] = useState(false);
  const [data, setData] = useState({
    skills: [],
    membersSize: null,
    time: null,
    formInput: {},
  });

  const { skills, membersSize, time, formInput } = data;
  const onNext = (value) => {
    switch (step) {
      case 1:
        setStep(2);
        return "";
      case 2:
        setStep(3);
        return "";
      case 3:
        setStep(4);
        return "";
      case 4:
        setStep(5);
        return "";
      default:
        return null;
    }
  };

  const handleBack = () => {
    if (hadsend) return;
    if (step !== 1) setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            onNext={onNext}
            skills={skills}
            data={data}
            setData={setData}
          />
        );
      case 2:
        return (
          <Step2
            onNext={onNext}
            membersSize={membersSize}
            data={data}
            setData={setData}
          />
        );
      case 3:
        return (
          <Step3 onNext={onNext} time={time} data={data} setData={setData} />
        );
      case 4:
        return (
          <Step4
            onNext={onNext}
            formInput={formInput}
            data={data}
            setData={setData}
            setHadsend={setHadsend}
          />
        );
      case 5:
        return null;
      default:
        return null;
    }
  };
  return (
    <div className="main-form">
      <div className="main-form-header">
        <div className="back" onClick={handleBack}>
          <LeftOutlined /> Previous Question
        </div>
        <div className="process-percent">{(step - 1) * 25}% Complete</div>
      </div>
      {renderStep()}
      {
        hadsend && <Result
        status="success"
        title="Your mail has been sent successfully!"
        subTitle="We will contact you within the shortest working time."
      />
      }
      
    </div>
  );
};

export default MainForm;
