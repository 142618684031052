import React, { useState, useMemo } from "react";
import { Layout, Menu, Collapse, Avatar } from "antd";
import logo from "../../logo.svg";
import logoW from "../../logo-white.svg";
const { Header, Content, Footer } = Layout;

const PageHeader = () => {
  let pathName = useMemo(
    () => window.location.pathname,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname]
  );
  const menu = [
    {
      label: (
        <a
          href="/#integrations"
          className={
            !!!pathName.split("/")[1] ? "text-white" : "text-secondary "
          }
        >
          Vetting Process
        </a>
      ),
      key: 1,
    },
    {
      label: <a href="/#features-big">Products</a>,
      key: 2,
    },
    {
      label: <a href="/#faq">How it Works</a>,
      key: 3,
    },
    {
      label: <a href="/404">Blog</a>,
      key: 4,
    },
    {
      label: <a href="/contact">About Us</a>,
      key: 5,
    },
    {
      label: (
        <a href="/contact" className="btn">
          Hire Top Talent
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="var(--token-3d971911-c57c-4f8e-b576-a461fe2fd3ab, rgb(255, 255, 255))"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="4 17 10 11 4 5"></polyline>
            <line x1="12" y1="19" x2="20" y2="19"></line>
          </svg>
        </a>
      ),
      key: 5,
    },
  ];
  const [active, setActive] = useState(false);
  const toggleMenu = () => setActive((active) => !active);
  return (
    <Header
      className="App-header"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="container">
        <div className={`App-header-inner ${active ? "active" : ""}`}>
          <div className="app-logo">
            <a href="/">
              <img
                src={!!!pathName.split("/")[1] ? logoW : logo}
                className="App-logo"
                alt="logo"
                width={97}
                height={14}
              />
            </a>
          </div>
          <div className="App-menu">
            <ul className="ant-menu">
              {menu.map((itm, index) => (
                <li className="ant-menu-item" key={index}>
                  {itm.label}
                </li>
              ))}
            </ul>
            {/* <Menu theme="light" collapsedWidth="0" items={menu} /> */}
          </div>
          <span className="hamburger" onClick={toggleMenu}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </span>
        </div>
      </div>
    </Header>
  );
};

export default PageHeader;
