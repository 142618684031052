import { Button, Select } from "antd";
import { skillsOptions } from "../../../constants";
import { useEffect, useState } from "react";
import "./styles.scss";
import { CloseOutlined, ArrowRightOutlined } from "@ant-design/icons";

const Step1 = ({ onNext, skills = [], data, setData }) => {
  const [popularSkills, setPopularSkills] = useState(skillsOptions);
  const [valueSelect, setValueSelect] = useState(null);

  useEffect(() => {
    const temp = skillsOptions.filter(
      (objA) => !skills.some((objB) => objB.value === objA.value)
    );
    setPopularSkills(temp);
  }, [skills]);

  const handleChange = (value, option) => {
    const checkSelected = skills?.find((e) => e?.value === value);
    if (!!checkSelected) {
      setValueSelect(null);
      return;
    }
    const temp = skillsOptions?.find((e) => e?.value === value);
    const temp2 = popularSkills?.filter((e) => e?.value !== option?.value);

    setData({ ...data, skills: [...skills, temp] });
    setValueSelect(null);
    setPopularSkills(temp2);
  };

  const handleRemove = (opt) => {
    const temp = skills?.filter((e) => e?.value !== opt?.value);
    setData({ ...data, skills: temp });
    setPopularSkills([...popularSkills, opt]);
  };

  const handleAdd = (opt) => {
    setData({ ...data, skills: [...skills, opt] });
    const temp = popularSkills?.filter((e) => e?.value !== opt?.value);
    setPopularSkills(temp);
  };

  return (
    <div className="step1-wrapper">
      <div className="step1-title">
        What skills would you like to see in your new team?
      </div>
      <div className="step1-sub-title">
        Select the desired areas of expertise
      </div>
      <div className="step1-select">
        <Select
          size="large"
          allowClear
          style={{ width: "100%" }}
          placeholder="Desired areas of expertise (e.g., JavaScript, Python, etc.)"
          onChange={handleChange}
          options={skillsOptions}
          value={valueSelect}
        />
        <div className="step1-select-results">
          {skills?.map((e, idx) => {
            return (
              <div key={e?.value} className="skill-item">
                {`+ ${e?.label}`}
                <span className="skill-item-icon">
                  <CloseOutlined onClick={() => handleRemove(e)} />
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="step1-body">
        <div className="step1-body-title">
          Popular skills for<strong>Developer</strong>:
        </div>
        <div className="popular-skills">
          {popularSkills?.map((e, idx) => {
            return (
              <div
                key={e?.value}
                className="skill-item"
                onClick={() => handleAdd(e)}
              >
                {`+ ${e?.label}`}
              </div>
            );
          })}
        </div>
        <div className="step1-button-wapper">
          <Button
            type="primary"
            size="large"
            disabled={skills?.length === 0}
            onClick={() => onNext(skills)}
          >
            Continue
            <ArrowRightOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Step1;
